<div class="date-time-filter-entry">
  <div class="equal-columns">

    <mat-form-field class="date-range">
      <mat-label>{{ 'DATE_TIME_FILTER.DATE_RANGE' | translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker" [min]="minimumDate" [max]="maximumDate">
        <input data-cy="start-date" matStartDate required
          placeholder="{{ 'DATE_TIME_FILTER.START_DATE_HINT' | translate}}" [(ngModel)]="dateTimeFilterEntry.startDate">
        <input data-cy="end-date" matEndDate required placeholder="{{ 'DATE_TIME_FILTER.END_DATE_HINT' | translate}}"
          [(ngModel)]="dateTimeFilterEntry.endDate">
      </mat-date-range-input>
      <mat-hint>{{ 'DATE_TIME_FILTER.DATE_HINT' | translate}}</mat-hint>
      <mat-datepicker-toggle data-cy="date-range-button" matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <div class="dow-and-time-row">
      <div class="dow">
        <div class="header-label">{{ 'DATE_TIME_FILTER.DAYS_OF_THE_WEEK' | translate }}</div>
        <mat-button-toggle-group [hideMultipleSelectionIndicator]="true" data-cy="days-of-week" multiple="true"
          [(value)]="dateTimeFilterEntry.daysOfWeek">
          <mat-button-toggle [value]="1">{{ 'DATE_TIME_FILTER.MONDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="2">{{ 'DATE_TIME_FILTER.TUESDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="3">{{ 'DATE_TIME_FILTER.WEDNESDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="4">{{ 'DATE_TIME_FILTER.THURSDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="5">{{ 'DATE_TIME_FILTER.FRIDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="6">{{ 'DATE_TIME_FILTER.SATURDAY' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="7">{{ 'DATE_TIME_FILTER.SUNDAY' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- TO-DO change the step according to the BE(ex:0.5) -->
      <div class="time">
        <div class="header-label">{{ 'DATE_TIME_FILTER.TIME' | translate }} ({{dateTimeFilterEntry.startTime}} &mdash;
          {{dateTimeFilterEntry.endTime}})</div>
        <mat-slider min="0" max="23" step="1" discrete [displayWith]="formatLabel" range>
          <input matSliderStartThumb [(ngModel)]="dateTimeFilterEntry.startTime">
          <input matSliderEndThumb [(ngModel)]="dateTimeFilterEntry.endTime">
        </mat-slider>
      </div>

      <div class="delete-button">
        <button data-cy="delete-button" mat-icon-button [disabled]="!canDelete"
          (click)="onDeleteClick(dateTimeFilterEntry);" data-cy="delete-time-filter-entry">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
