import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ModeTransitionsResponse } from '@shared/resources/analysis/mode-chains-response';
import { TransportModeType } from '@shared/resources/analysis/transport-mode-type';
import { ofType } from '@shared/utils/typing-utils';
import * as echarts from 'echarts';
import { filter, takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartService } from '../../services/chart.service';
import { GraphStyle } from '../../utils/constants/graph-style';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';

interface NodeInfo {
  nodeId: string;
  type: 'fromMode' | 'toMode';
  mode: TransportModeType;
  label: string;
  value: number;
  journeys: number;
  persons: number;
  color: string;
}

@Component({
  selector: 'app-mode-transitions-chart',
  templateUrl: './mode-transitions-chart.component.html',
  styleUrl: './mode-transitions-chart.component.scss'
})
export class ModeTransitionsChartComponent implements OnInit {

  private static readonly THIS_PANEL = PanelType.MODE_CHAIN;

  public readonly HEIGHT_PX = 350;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private echartsInstance: echarts.ECharts;
  private modeTransitionsResponse: ModeTransitionsResponse['counts'] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private translateService: TranslateService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => this.fetchModeTransitions());

    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => this.refresh());

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      this.refresh();
    });

    this.chartService.exportClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === ModeTransitionsChartComponent.THIS_PANEL))
      .subscribe(() => {
        chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      });
    this.chartService.copyClipboardClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === ModeTransitionsChartComponent.THIS_PANEL))
      .subscribe(() => {
        chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      });
    this.chartService.exportCsvClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === ModeTransitionsChartComponent.THIS_PANEL))
      .subscribe(() => {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.MODE_CHAIN');
        chartService.exportDataToCsv(fileName, this.modeTransitionsResponse);
      });
  }

  public ngOnInit() {
    this.fetchModeTransitions();
  }

  private fetchModeTransitions() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getModeTransitionsDiagram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(modeTransitionsResponse => {
        this.modeTransitionsResponse = modeTransitionsResponse.counts;
        this.initChartOnlyOnce();
        this.refresh();
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private refresh() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions());
    }
  }

  private getChartOptions() {
    const nodeMap = new Map<string, NodeInfo>();
    const links = this.modeTransitionsResponse.map(modeTransition => ({
      source: this.generateNode(nodeMap, 'fromMode', modeTransition),
      target: this.generateNode(nodeMap, 'toMode', modeTransition),
      value: this.activeToggle === JourneysOrPersons.JOURNEYS ? modeTransition.journeys : modeTransition.persons,
      journeys: modeTransition.journeys,
      persons: modeTransition.persons
    }));

    const nodes = Array.from(nodeMap.values())
      .sort((a, b) => b.value - a.value) // Largest on top
      .map(node => ({
        name: node.nodeId,
        itemStyle: { color: node.color },
        journeys: node.journeys,
        persons: node.persons
      }));

    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        ...this.getTooltipFormatter(),
        ...GraphStyle.TOOLTIP_STYLE
      },
      series: {
        type: 'sankey',
        layout: 'none',
        layoutIterations: 0,
        draggable: false,
        left: '15%',
        right: '15%',
        top: 0,
        bottom: 5,
        emphasis: {
          focus: 'adjacency'
        },
        levels: [{
          depth: 0,
          label: {
            position: 'left'
          }
        }],
        label: {
          fontSize: 10
        },
        nodes,
        links
      }
    };
  }

  private getTooltipFormatter() {
    const journeysTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS');
    const personsTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS');
    const totalJourneys = this.modeTransitionsResponse.map(r => r.journeys).reduce((sum, current) => sum + current, 0);

    return {
      formatter: function (params: any) {
        switch (params.dataType) {
          case 'edge': // Tooltip for transition
            return GraphUtils.getTooltipFormatterExpression({
              journeysTooltip, totalJourneys, personsTooltip, excludeColorCircle: true
            }, params);
          case 'node': // Tooltip for mode
            return GraphUtils.getTooltipFormatterExpression({ journeysTooltip, totalJourneys }, params);
          default:
            return '';
        }
      }
    };
  }

  private generateNode(nodeMap: Map<string, NodeInfo>, type: NodeInfo['type'], modeTransition: ModeTransitionsResponse['counts'][0]) {
    const mode = modeTransition[type];
    const label = this.translateService.instant('ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.' + mode) as string;
    const nodeId = `${label}${type === 'fromMode' ? '\u2009' : '\u2000'}`; // Trick to use same label for origin and destination nodes
    let node = nodeMap.get(nodeId);
    if (!node) {
      node = ofType<NodeInfo>({
        nodeId, type, mode, label, value: 0, journeys: 0, persons: 0,
        color: Constants.COLORS_PER_MODE[mode] ?? Constants.COLORS_PER_MODE.UNKNOWN
      });
      nodeMap.set(node.nodeId, node);
    }
    node.value += this.activeToggle === JourneysOrPersons.JOURNEYS ? modeTransition.journeys : modeTransition.persons;
    node.journeys += modeTransition.journeys;
    node.persons += modeTransition.persons;
    return node.nodeId;
  }
}
