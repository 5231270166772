<div class="kpi-block">
  <mat-icon>{{ kpiDescriptor.icon }}</mat-icon>
  <div class="kpi-content">
    <div class="label">
      {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.KPI_PANEL.' + kpiDescriptor.localeKey | translate | lowercase }}
    </div>
    <div class="values">
      <div class="large-value">
        {{ (largeValue | number:numberFormat ) }} {{ kpiDescriptor.measurementUnit | translate }}
      </div>
    </div>
  </div>
</div>
