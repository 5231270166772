import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable, of, switchMap } from 'rxjs';

export type AuthState = 'OK' | 'UNAUTHENTICATED' | 'NO_REGISTRATION' | 'EXPIRED' | 'UNKNOWN_ERROR';

interface AuthorizedJwtPayloadFields {
  applicationId?: string;
  roles?: string[];
  licenseExpireDate?: {
    epochSecond: number;
    nano: number;
  },
  groupMemberships?: string[];
  licenseType?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isTrialUser: boolean = false;

  private readonly TRIAL_LICENSE = 'TRIAL';

  constructor(private oidcSecurityService: OidcSecurityService) { }

  public isAuthenticatedAndAuthorized(): Observable<AuthState> {
    return this.oidcSecurityService.checkAuth()
      .pipe(
        switchMap(loginResponse => loginResponse.isAuthenticated
          ? this.checkAuthorization()
          : of('UNAUTHENTICATED' as AuthState))
      );
  }

  private checkAuthorization(): Observable<AuthState> {
    return this.oidcSecurityService.getPayloadFromAccessToken()
      .pipe(
        map((jwtPayload: AuthorizedJwtPayloadFields) => {
          if (!jwtPayload) {
            return 'UNKNOWN_ERROR';
          } else if (!jwtPayload.applicationId || !jwtPayload.roles) {
            // If the field 'Roles' is missing, there was an account, but no registration to this application
            return 'NO_REGISTRATION';
          } else if (this.isLicenseExpired(jwtPayload)) {
            return 'EXPIRED';
          } else {
            this.setLicenseType(jwtPayload);
            return 'OK';
          }
        })
      );
  }

  private setLicenseType(jwtPayload: AuthorizedJwtPayloadFields) {
    this.isTrialUser = jwtPayload.licenseType === this.TRIAL_LICENSE;
  }

  private isLicenseExpired(jwtPayload: AuthorizedJwtPayloadFields) {
    if (jwtPayload.licenseExpireDate) {
      const expireDate = new Date(jwtPayload.licenseExpireDate.epochSecond * 1_000);
      if (new Date() > expireDate) {
        return true;
      }
    }
    return false;
  }

  public login() {
    this.oidcSecurityService.authorize();
  }

  public logoff() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => { });
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

}
