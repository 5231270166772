<mat-expansion-panel class="extra-explain" [disabled]="!analysis">
  <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
    <mat-panel-title>
      {{ 'ANALYSIS_INPUT.FILTER_SETTINGS' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <a data-cy="back-button" class="back-button primary-button" mat-stroked-button
    routerLink="/analysis-config/{{analysis?.analysisId}}"
    matTooltip="{{'ANALYSIS_OVERVIEW.NAVIGATE_TO_ANALYSIS_OVERVIEW' | translate}}">
    {{ 'ANALYSIS_OVERVIEW.EDIT_ANALYSIS' | translate }}
    <mat-icon>edit</mat-icon>
  </a>

  <div class="body">
    @if (geoFilterOverviewEntries.length > 0) {
    <p>{{ 'ANALYSIS_INPUT.FILTER_GEO' | translate }}</p>
    <ul>
      @for (entry of geoFilterOverviewEntries; track $index) {
      <li>
        {{'GEO_FILTER.' + entry.type | translate}}
        {{'GEO_FILTER.' + entry.geoType | translate: {names: entry.names} | ellipsis:250 }}
      </li>
      }
    </ul>
    }

    @if (dateTimeFilterOverviewEntries.length > 0) {
    <p>{{ 'ANALYSIS_INPUT.FILTER_TIME' | translate }}</p>
    <ul>
      @for (entry of dateTimeFilterOverviewEntries; track $index; let last = $last) {
      <li>
        {{ 'DATE_TIME_FILTER.FROM' | translate | titlecase }}
        {{ entry.startDate | date:'M-d-yyyy' }}
        {{ 'DATE_TIME_FILTER.THROUGH' | translate }}
        {{ entry.endDate | date: 'M-d-yyyy' }}
        {{ 'DATE_TIME_FILTER.ON' | translate }}
        @if (entry.dayKeys.length === 1) {
        {{ entry.dayKeys[0] | translate }}
        } @else {
        @for (dayKey of entry.dayKeys; track dayKey; let last = $last) {
        {{ (dayKey | translate | slice: 0:-1) + (last ? '' : ', ') }}
        }
        }
        {{ 'DATE_TIME_FILTER.BETWEEN' | translate }}
        {{ entry.startTime + '-' + entry.endTime }}
        {{ 'DATE_TIME_FILTER.HOUR' | translate }}
      </li>
      }
    </ul>
    }

    @if (transportFilterOverviewEntries.length > 0) {
    <p>{{ 'ANALYSIS_INPUT.FILTER_MOVEMENTS' | translate }}</p>
    <ul>
      @for (transportFilterEntry of transportFilterOverviewEntries; track $index) {
      <li>
        {{ transportFilterEntry.langKey | translate }}:
        @if (transportFilterEntry.value) {
        {{ transportFilterEntry.value }} {{ (transportFilterEntry.unitLangKey ?? '') | translate }}
        }
        @else {
        @for (langKey of transportFilterEntry.subLangKeys; track $index; let last = $last) {
        {{ (langKey | translate) + (last ? '' : ', ') }}
        }
        }
      </li>
      }
    </ul>
    }

    @if (personFilterOverviewEntries.length > 0 || personsFromAnalysis) {
    <p>{{ 'ANALYSIS_INPUT.FILTER_PERSONS' | translate }}</p>
    <ul>
      @if (personsFromAnalysis) {
      <li>
        <a routerLink="/analysis/{{personsFromAnalysis.analysisId}}" target="_blank">
          {{ 'PERSON_FILTER.PANEL_ANALYSIS_ACTIVE' | translate:{title: personsFromAnalysis.title} }}
        </a>
      </li>
      }
      @for (personFilterEntry of personFilterOverviewEntries; track $index) {
      <li>
        {{ personFilterEntry.langKey | translate }}:
        @if (personFilterEntry.value) {
        {{ personFilterEntry.value }}
        }
        @else {
        @for (langKey of personFilterEntry.subLangKeys; track $index; let last = $last) {
        {{ (langKey | translate) + (last ? '' : ', ') }}
        }
        }
      </li>
      }
    </ul>
    }
  </div>

</mat-expansion-panel>
