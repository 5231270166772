import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-page-layout',
  templateUrl: './main-page-layout.component.html',
  styleUrls: ['./main-page-layout.component.scss']
})
export class MainPageLayoutComponent {
  @Input() public pageLabel: string;
  @Input() public footer = false;
  @Input() public styledBackground = false;
}
