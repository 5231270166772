import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../../message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public static readonly BYPASS_ERROR_INTERCEPTOR_TOKEN = new HttpContextToken(() => false);

  constructor(private messageService: MessageService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(ErrorInterceptor.BYPASS_ERROR_INTERCEPTOR_TOKEN)) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          const errorResponse = err;
          console.log(errorResponse.statusText + ' - ' + errorResponse.message);
          this.messageService.showErrorSnackBar(this.getGeneralMessageKey(errorResponse.status));
        }
        return throwError(() => err);
      }));
    }
  }

  private getGeneralMessageKey(statusCode: number): string {
    switch (statusCode) {
      case 400:
        return 'SNACKBAR_HTTP_ERROR.STATUS_BAD_REQUEST';
      case 401:
        return 'SNACKBAR_HTTP_ERROR.STATUS_UNAUTHORIZED';
      case 403:
        return 'SNACKBAR_HTTP_ERROR.STATUS_FORBIDDEN';
      case 404:
        return 'SNACKBAR_HTTP_ERROR.STATUS_NOTFOUND';
      case 504:
        return 'SNACKBAR_HTTP_ERROR.STATUS_TIMEOUT';
      case 0:
        return 'SNACKBAR_HTTP_ERROR.STATUS_NONE';
      case 500:
      default:
        return 'SNACKBAR_HTTP_ERROR.STATUS_SERVER_ERROR';
    }
  }

}
