import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CreateAnalysis } from '@shared/resources/analysis/create-analysis';

@Component({
  selector: 'app-analysis-metadata',
  templateUrl: './analysis-metadata.component.html',
  styleUrl: './analysis-metadata.component.scss'
})
export class AnalysisMetadataComponent implements OnInit, OnChanges {
  @Input({ required: true }) public analysis: CreateAnalysis;
  @Output() metadataFormIsValid: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public metadataForm: FormGroup;

  private readonly MAX_DESCRIPTION_LENGTH = 200;

  constructor(private translateService: TranslateService) {
    this.metadataForm = new FormGroup({
      title: new FormControl('', [Validators.required, this.whitespaceValidator()]),
      projectCode: new FormControl(''),
      description: new FormControl('', Validators.maxLength(this.MAX_DESCRIPTION_LENGTH))
    });

    this.initializeFormSubscriptions();
  }

  public ngOnInit(): void {
    this.translateService.get('ANALYSIS_METADATA_INPUT.DEFAULT_TITLE')
      .subscribe((title: string) => {
        if (this.analysis.title === '') {
          this.metadataForm.patchValue({ title });
          this.analysis.title = title;
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['analysis']) {
      this.metadataForm.patchValue({
        title: this.analysis.title ?? '',
        projectCode: this.analysis.projectCode ?? '',
        description: this.analysis.description ?? ''
      });
    }
  }

  private initializeFormSubscriptions(): void {
    this.metadataForm.valueChanges.pipe(takeUntilDestroyed())
      .subscribe(values => {
        this.analysis.title = values.title ?? '';
        this.analysis.projectCode = values.projectCode ?? '';
        this.analysis.description = values.description ?? '';
      });

    this.metadataForm.statusChanges.pipe(takeUntilDestroyed())
      .subscribe(status => {
        this.metadataFormIsValid.emit(status === 'VALID');
      });
  }

  private whitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any; } | null => {
      const isWhitespace = (control.value ?? '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': 'value is only whitespace' };
    };
  }

}
