import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [AuthModule.forRoot({
    config: {
      authority: environment.fusionAuth.issuer,
      unauthorizedRoute: '/',
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.fusionAuth.clientId,
      scope: 'openid profile offline_access email https://graph.microsoft.com/user.read',
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      renewTimeBeforeTokenExpiresInSeconds: 30,
      logLevel: environment.production ? LogLevel.Error : LogLevel.Warn
    }
  })],
  exports: [AuthModule],
})
export class AuthConfigModule { }
