import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';

export class DisclaimerOptions {
  showIfAccepted: boolean;
}

export interface DisclaimerDialogData {
  options: DisclaimerOptions;
}

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrl: './disclaimer-dialog.component.scss'
})
export class DisclaimerDialogComponent {

  public accepted: boolean = false;

  public static showDisclaimer(dialog: MatDialog, options: DisclaimerOptions) {
    if (options.showIfAccepted || !this.hasDisclaimerBeenAccepted()) {
      const dialogConfig: MatDialogConfig<DisclaimerDialogData> = {
        disableClose: true,
        autoFocus: false,
        width: '800px',
        backdropClass: 'darker-backdrop',
        data: { options }
      };
      dialog.open(DisclaimerDialogComponent, dialogConfig);
    }
  }

  private static hasDisclaimerBeenAccepted() {
    return localStorage.getItem(LocalStorageConstants.IS_DISCLAIMER_ACCEPTED) === 'true';
  }

  public onCloseClick() {
    localStorage.setItem(LocalStorageConstants.IS_DISCLAIMER_ACCEPTED, 'true');
  }
}
