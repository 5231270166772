import { Environment } from './environment-interface';

/**
 * Local development environment settings. Change these according to your situation and save as `environment.development.ts`.
 */
export const environment: Environment = {
  production: false,
  apiUri: '', // goes via proxy.conf.json
  fusionAuth: {
    clientId: '0f60b159-2bd0-4001-9719-27fc3839bfc5',
    issuer: 'https://accp.sso.dat.nl'
  }
};
