import { ofType } from '../../utils/typing-utils';
import { Analysis } from './analysis';
import { CREATE_EMPTY_ANALYSIS_FILTER_OPTIONS } from './analysis-filter-options';

export type CreateAnalysis = Pick<Analysis, 'title' | 'projectCode' | 'description' | 'analysisFilterOptions'>;

export const CREATE_EMPTY_ANALYSIS = () => (ofType<CreateAnalysis>({
  title: '',
  description: '',
  projectCode: '',
  analysisFilterOptions: CREATE_EMPTY_ANALYSIS_FILTER_OPTIONS()
}));
