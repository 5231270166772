import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';
import { LocalSpinner } from '../../utils/local-spinner';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CrossFilteringService } from '../../services/cross-filtering.service';
import { takeUntil } from 'rxjs';
import { AnalysisHttpService } from '../../services/http/analysis-http.service';
import { ChartService } from '../../services/chart.service';
import { ConfidenceLevel, StatisticsService } from '../../services/statistics-service';

interface StatisticsField {
  subject: 'MODAL_SPLIT' | 'JOURNEYS';
  marginOfErrorPercentage: number;
  confidenceLevel: ConfidenceLevel;
}

@Component({
  selector: 'app-reliability-statistics',
  templateUrl: './reliability-statistics.component.html',
  styleUrl: './reliability-statistics.component.scss'
})
export class ReliabilityStatisticsComponent implements OnInit {
  @Input() public analysisId: number;
  @ViewChild('export') divToExport: ElementRef;

  public spinner = new LocalSpinner();
  public readonly LOADER_COUNT = 2;

  private panelType: PanelType = PanelType.RELIABILITY;

  public crossfilteredPersons = 0;
  public statisticsFields: StatisticsField[] = [
    { subject: 'MODAL_SPLIT', marginOfErrorPercentage: 0, confidenceLevel: ConfidenceLevel.LOW },
    { subject: 'JOURNEYS', marginOfErrorPercentage: 0, confidenceLevel: ConfidenceLevel.LOW }
  ];

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private chartService: ChartService,
    private translateService: TranslateService,
    private statisticsService: StatisticsService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchAnalysisStatistics();
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportKpi(this.divToExport).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportKpiToClipboard(this.divToExport).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.STATISTICS');
        const csvData = this.getStatisticsAsCsv();
        this.chartService.exportDataToCsv(fileName, csvData);
      }
    });
  }

  public ngOnInit() {
    this.fetchAnalysisStatistics();
  }

  public getConfidenceClassifier(confidenceValue: ConfidenceLevel): string {
    switch (confidenceValue) {
      case ConfidenceLevel.HIGH:
        return 'mood';
      case ConfidenceLevel.MEDIUM:
        return 'sentiment_neutral';
      case ConfidenceLevel.LOW:
        return 'close';
    }
  }

  private fetchAnalysisStatistics() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    this.analysisHttpService
      .getAnalysisCounts(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(response => {
        this.updateStatisticsFields(response.persons);
        this.crossfilteredPersons = response.persons;
      });
  }

  private updateStatisticsFields(sampleSize: number) {
    this.statisticsFields = this.statisticsFields.map(field => {
      switch (field.subject) {
        case 'MODAL_SPLIT':
          return { ...this.statisticsService.getMarginOfErrorForMaxVariability(sampleSize), subject: field.subject };
        case 'JOURNEYS':
          return { ...this.statisticsService.getConservativeMarginOfError(sampleSize), subject: field.subject };
        default:
          return field;
      }
    });
  }

  private getStatisticsAsCsv() {
    return this.statisticsFields.map(f => {
      return {
        subject: this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.STATISTICS_PANEL.' + f.subject),
        marginOfErrorPercentage: f.marginOfErrorPercentage.toFixed(1),
        confidenceClassifier: f.confidenceLevel
      };
    });
  }
}
