<app-main-page-layout [pageLabel]="('GLOBAL.NVP_ANALYZER' | translate) + getAnalysisTitle()">
  <div class="main-container" [class.left-collapsed]="leftSidebarCollapsed"
    [class.right-collapsed]="rightSidebarCollapsed">

    <app-sidebar side="left" [width]="leftSidebarWidth" [(collapsed)]="leftSidebarCollapsed">
      <div class="sidebar-content">

        <app-analysis-filter-overview [analysis]="analysis"></app-analysis-filter-overview>

        <div class="layer-groups">
          <mat-expansion-panel [expanded]="true" #expansionPanelBackground>
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
              <mat-panel-title>
                <mat-checkbox [(ngModel)]="groupBackgroundActive" data-cy="layergroup-chk-background"
                  (change)="onGroupActiveChange($event, expansionPanelBackground)" (click)="$event.stopPropagation();">
                  {{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_BACKGROUND' | translate }}
                </mat-checkbox>
                <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_BACKGROUND_TOOLTIP' | translate }}"
                  matTooltipClass="wide-tooltip" matTooltipShowDelay="300"
                  (click)="$event.stopPropagation()">info</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-radio-group color="accent">
              <div class="item-with-layer-controls">
                <mat-radio-button [checked]="true" data-cy="layer-background1">
                  {{ 'ANALYSIS_OVERVIEW.LAYERS.BACKGROUND_NETHERLANDS_PDOK' | translate }}
                </mat-radio-button>
                <ng-container *ngTemplateOutlet="layerControlsButton; context: {
                    active: groupBackgroundActive,
                    layerType: LayerType.BACKGROUND_NETHERLANDS_PDOK,
                    expanded: layerControlsExpanded[LayerType.BACKGROUND_NETHERLANDS_PDOK]
                  }">
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="layerControls; context: {
                  active: groupBackgroundActive,
                  layerType: LayerType.BACKGROUND_NETHERLANDS_PDOK,
                  layerStyling: layerStyling[LayerType.BACKGROUND_NETHERLANDS_PDOK],
                  layerDefaultStyling: layerDefaultStyling[LayerType.BACKGROUND_NETHERLANDS_PDOK]
                }">
              </ng-container>
            </mat-radio-group>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true" #expansionPanelOrigins>
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
              <mat-panel-title>
                <mat-checkbox [(ngModel)]="groupOriginActive" data-cy="layergroup-chk-origins"
                  (change)="onGroupActiveChange($event, expansionPanelOrigins)" (click)="$event.stopPropagation();">
                  {{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_ORIGIN' | translate }}
                </mat-checkbox>
                <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_ORIGIN_TOOLTIP' | translate }}"
                  matTooltipClass="wide-tooltip" matTooltipShowDelay="300"
                  (click)="$event.stopPropagation()">info</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-radio-group color="accent" [(ngModel)]="activeOriginLayer"
              (change)="groupOriginActive = true; onLayerChange()">
              @for (layer of polygonLayers; track layer) {
              @if (layer.type === 'origin' && layer.visible) {
              <div class="item-with-layer-controls">
                <mat-radio-button [value]="layer.level"
                  [attr.data-cy]="'layer-' + layer.type + '-' + layer.level + '-polygons'">
                  {{ ('ANALYSIS_OVERVIEW.LAYERS.' + activeToggle) | translate }}
                  {{ ('ANALYSIS_OVERVIEW.LAYERS.' + (layer.layerType | uppercase)) | translate }}
                  <mat-spinner [diameter]="24" *ngIf="layerSpinners[layer.layerType].state$ | async"></mat-spinner>
                </mat-radio-button>
                <ng-container *ngTemplateOutlet="layerControlsButton; context: {
                    active: groupOriginActive && layer.level === activeOriginLayer,
                    layerType: layer.layerType,
                    expanded: layerControlsExpanded[layer.layerType]
                  }">
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="layerControls; context: {
                  active: groupOriginActive && layer.level === activeOriginLayer,
                  layerType: layer.layerType,
                  layerStyling: layerStyling[layer.layerType],
                  layerDefaultStyling: layerDefaultStyling[layer.layerType]
                }">
              </ng-container>
              }
              }
            </mat-radio-group>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true" #expansionPanelDestinations>
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
              <mat-panel-title>
                <mat-checkbox [(ngModel)]="groupDestinationActive" data-cy="layergroup-chk-destinations"
                  (change)="onGroupActiveChange($event, expansionPanelDestinations)"
                  (click)="$event.stopPropagation();">
                  {{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_DESTINATION' | translate }}
                </mat-checkbox>
                <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_DESTINATION_TOOLTIP' | translate }}"
                  matTooltipClass="wide-tooltip" matTooltipShowDelay="300"
                  (click)="$event.stopPropagation()">info</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-radio-group color="accent" [(ngModel)]="activeDestinationLayer"
              (change)="groupDestinationActive = true; onLayerChange()">
              @for (layer of polygonLayers; track layer) {
              @if (layer.type === 'destination' && layer.visible) {
              <div class="item-with-layer-controls">
                <mat-radio-button [value]="layer.level"
                  [attr.data-cy]="'layer-' + layer.type + '-' + layer.level + '-polygons'">
                  {{ ('ANALYSIS_OVERVIEW.LAYERS.' + activeToggle) | translate }}
                  {{ ('ANALYSIS_OVERVIEW.LAYERS.' + (layer.layerType | uppercase)) | translate }}
                  <mat-spinner [diameter]="24" *ngIf="layerSpinners[layer.layerType].state$ | async"></mat-spinner>
                </mat-radio-button>
                <ng-container *ngTemplateOutlet="layerControlsButton; context: {
                    active: groupDestinationActive && layer.level === activeDestinationLayer,
                    layerType: layer.layerType,
                    expanded: layerControlsExpanded[layer.layerType]
                  }">
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="layerControls; context: {
                  active: groupDestinationActive && layer.level === activeDestinationLayer,
                  layerType: layer.layerType,
                  layerStyling: layerStyling[layer.layerType],
                  layerDefaultStyling: layerDefaultStyling[layer.layerType]
                }">
              </ng-container>
              }
              }
            </mat-radio-group>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true" #expansionPanelRoutes>
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
              <mat-panel-title>
                <mat-checkbox [(ngModel)]="groupRoutesActive" data-cy="layergroup-chk-routes"
                  (change)="onGroupActiveChange($event, expansionPanelRoutes)" (click)="$event.stopPropagation();">
                  {{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_ROUTES' | translate }}
                </mat-checkbox>
                <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.GROUP_ROUTES_TOOLTIP' | translate }}"
                  matTooltipClass="wide-tooltip" matTooltipShowDelay="300"
                  (click)="$event.stopPropagation()">info</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="item-with-layer-controls">
              <mat-radio-button color="accent" [checked]="true" data-cy="layer-links">
                {{ ('ANALYSIS_OVERVIEW.LAYERS.' + activeToggle) | translate }}
                {{ 'ANALYSIS_OVERVIEW.LAYERS.BANDWIDTHS' | translate }}
                <mat-spinner [diameter]="24" *ngIf="layerSpinners['BANDWIDTHS'].state$ | async"></mat-spinner>
              </mat-radio-button>
              <ng-container *ngTemplateOutlet="layerControlsButton; context: {
                  active: groupRoutesActive,
                  layerType: LayerType.BANDWIDTHS,
                  expanded: layerControlsExpanded[LayerType.BANDWIDTHS]
                }">
              </ng-container>
            </div>
            <ng-container *ngTemplateOutlet="layerControls; context: {
                active: groupRoutesActive,
                layerType: LayerType.BANDWIDTHS,
                layerStyling: layerStyling[LayerType.BANDWIDTHS],
                layerDefaultStyling: layerDefaultStyling[LayerType.BANDWIDTHS]
              }">
            </ng-container>
          </mat-expansion-panel>

        </div>
      </div>
    </app-sidebar>

    <div #map class="map"></div>

    @if (groupOriginActive || groupDestinationActive) {
    <div class="legend">
      <div class="legend-range-title">
        <div>0</div>
        <div>Max</div>
      </div>
      @if (groupOriginActive) {
      <div class="legend-range-bar" [style.background]="LEGEND_ORIGIN_POLYGON_GRADIENT_CSS"></div>
      }
      @if (groupDestinationActive) {
      <div class="legend-range-bar" [style.background]="LEGEND_DESTINATION_POLYGON_GRADIENT_CSS"></div>
      }
    </div>
    }

    <app-sidebar side="right" [width]="rightSidebarWidth" [(collapsed)]="rightSidebarCollapsed">
      <app-analysis-diagram-bar [analysis]="analysis"></app-analysis-diagram-bar>
    </app-sidebar>

  </div>

  <app-page-spinner message="ANALYSIS_OVERVIEW.LOADING_ANALYSIS"></app-page-spinner>

</app-main-page-layout>

<ng-template #layerControlsButton let-active="active" let-layerType="layerType" let-expanded="expanded">
  @if (active) {
  <button mat-icon-button class="layer-controls-button" [class.active]="expanded"
    [class.changed]="!expanded && isLayerControlsDifferentFromDefault(layerType)"
    (click)="toggleLayerControlsExpanded(layerType)">
    <mat-icon>tune</mat-icon>
  </button>
  }
</ng-template>

<ng-template #layerControls let-active="active" let-layerType="layerType" let-layerStyling="layerStyling"
  let-layerDefaultStyling="layerDefaultStyling">
  @if (active && $any(layerControlsExpanded)[layerType]) {
  <div class="layer-controls">
    @if (layerType === LayerType.BANDWIDTHS) {
    <div class="row">
      <div>{{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.SCALEFACTOR' | translate }}:</div>
      <mat-slider [min]="0.1" [max]="10.0" [step]="0.1" color="accent">
        <input matSliderThumb [ngModel]="layerStyling.scaleFactor" (valueChange)="setBandwidthsScaleFactor($event)">
      </mat-slider>
      <button mat-icon-button (click)="setBandwidthsScaleFactor(layerDefaultStyling.scaleFactor)"
        [disabled]="layerStyling.scaleFactor === layerDefaultStyling.scaleFactor"
        matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.RESET_SCALEFACTOR' | translate }}">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    }
    <div class="row">
      <div>{{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.TRANSPARENCY' | translate }}:</div>
      <mat-slider [min]="0.0" [max]="0.95" [step]="0.01" color="accent">
        <input matSliderThumb [ngModel]="layerStyling.transparency" (valueChange)="setTransparency(layerType, $event)">
      </mat-slider>
      <button mat-icon-button (click)="setTransparency(layerType, layerDefaultStyling.transparency)"
        [disabled]="layerStyling.transparency === layerDefaultStyling.transparency"
        matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.RESET_TRANSPARENCY' | translate }}">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    @if (layerType !== LayerType.BACKGROUND_NETHERLANDS_PDOK) {
    <div class="row">
      <mat-checkbox [ngModel]="layerStyling.labels" (change)="setLabels(layerType, $event.checked)"
        labelPosition="before">
        {{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.LABELS' | translate }}:
      </mat-checkbox>
      <button mat-icon-button (click)="setLabels(layerType, layerDefaultStyling.labels)"
        [disabled]="layerStyling.labels === layerDefaultStyling.labels"
        matTooltip="{{ 'ANALYSIS_OVERVIEW.LAYERS.LAYER_CONTROLS.RESET_LABELS' | translate }}">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    }

  </div>
  }
</ng-template>
