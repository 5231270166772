import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/utils/constants/constants';

export class BaseHttpService {
  constructor(protected httpClient: HttpClient) { }

  protected get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(this.expandUrl(url));
  }

  protected post(url: string, body: any): Observable<any> {
    return this.httpClient.post(this.expandUrl(url), body);
  }

  protected postT<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(this.expandUrl(url), body);
  }

  protected putT<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(this.expandUrl(url), body);
  }

  protected delete(url: string): Observable<any> {
    return this.httpClient.delete(this.expandUrl(url));
  }

  private expandUrl(url: string): string {
    if (
      url.length > 0 &&
      (url.lastIndexOf('http:', 0) === 0 || url.lastIndexOf('https:', 0) === 0)
    ) {
      return url;
    } else {
      return `${Constants.API_PREFIX}/${url}`;
    }
  }
}
