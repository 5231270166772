<mat-toolbar class="toolbar">
  <mat-toolbar-row class="toolbar-row">
    <a routerLink="/analysis-list" class="logo"><img alt="logo" src="/assets/headerlogo_wit.svg" /></a>
    <span class="page-name">{{pageLabel}} </span>
    <span class="spacer"></span>
    <span class="user-name">{{ userData?.name || userData?.email }}</span>
    <img class="avatar" data-cy="userMenu" alt="avatar" src="{{getUserMediaImageUrl()}}" [matMenuTriggerFor]="userMenu">
    <mat-menu class="header-menu-right" #userMenu="matMenu" xPosition="before">
      <button mat-menu-item [matMenuTriggerFor]="languageSubMenu">{{'HEADER.USER.LANGUAGE' | translate}}</button>
      <mat-menu #languageSubMenu="matMenu">
        @for (language of languages; track language) {
        <button mat-menu-item (click)="onLanguageClick(language)">
          <mat-icon>{{ currentLanguage === language ? 'radio_button_checked' : 'radio_button_unchecked' }}
          </mat-icon>
          <span><img alt="flag icon" src="/assets/i18n/{{ language }}.png">&nbsp;
            {{'HEADER.USER.LANGUAGE.' + language.toUpperCase() | translate}}</span>
        </button>
        }
      </mat-menu>
      <a mat-menu-item href="https://www.goudappel.nl/{{currentLanguage}}/privacy-statement"
        target="_blank">{{'HEADER.USER.PRIVACY' | translate}}</a>
      <button mat-menu-item (click)="onShowNvpDisclaimerClick()">{{'HEADER.USER.DISCLAIMER' | translate}}</button>
      <a mat-menu-item [href]="SUPPORT_URL">{{'HEADER.USER.FEEDBACK' | translate}}</a>
      <button mat-menu-item (click)="onLogoutClick()">{{'HEADER.USER.LOGOUT' | translate}}</button>
    </mat-menu>
    <app-app-drawer></app-app-drawer>
  </mat-toolbar-row>
</mat-toolbar>
