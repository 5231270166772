import { Bounds } from '@shared/constants/bounds';
import { PersonFilter } from '@shared/resources/analysis/personfilter/personal-filter';

export interface PersonFilterOverviewEntry {
  langKey: string;
  value?: string;
  subLangKeys?: string[];
}

export class PersonFilterOverviewGenerator {

  public generate(personFilter: PersonFilter) {
    const entries: PersonFilterOverviewEntry[] = [];
    this.addRangedEntry(entries, 'AGE',
      personFilter.ageMin, personFilter.ageMax,
      Bounds.MIN_AGE, Bounds.MAX_AGE);
    this.addEnumEntry(entries, 'GENDER', personFilter.genders);
    this.addEnumEntry(entries, 'POSSESSES_CAR', personFilter.possessesCar);
    this.addEnumEntry(entries, 'HAS_DRIVERS_LICENSE', personFilter.hasDriversLicense);
    this.addEnumEntry(entries, 'URBAN_DENSITY', personFilter.urbanDensity);
    this.addEnumEntry(entries, 'YEAR_INCOME_TYPES', personFilter.yearIncome);
    this.addEnumEntry(entries, 'HOUSEHOLD_TYPE', personFilter.householdType);
    this.addRangedEntry(entries, 'HOUSEHOLD_SIZE',
      personFilter.householdSizeMin, personFilter.householdSizeMax,
      Bounds.MIN_HOUSEHOLDS, Bounds.MAX_HOUSEHOLDS);
    this.addEnumEntry(entries, 'HIGHEST_EDUCATION_TYPES', personFilter.highestEducation);
    this.addEnumEntry(entries, 'OCCUPANCY_TYPES', personFilter.occupancy);
    return entries;
  }

  private addEnumEntry(entries: PersonFilterOverviewEntry[], langKey: string, values: string[]) {
    if (values.length > 0) {
      entries.push({
        langKey: `PERSON_FILTER.${langKey}.TITLE`,
        subLangKeys: values.map(value => `PERSON_FILTER.${langKey}.TYPES.${value}`)
      });
    }
  }

  private addRangedEntry(entries: PersonFilterOverviewEntry[], langKey: string, valueMin: number, valueMax: number, boundsMin: number, boundsMax: number) {
    if (valueMin !== boundsMin || valueMax !== boundsMax) {
      entries.push({ langKey: `PERSON_FILTER.${langKey}`, value: `${valueMin} \u2014 ${valueMax}` });
    }
  }

}
