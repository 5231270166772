import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { Analysis } from '@shared/resources/analysis/analysis';
import { takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { LocalSpinner } from 'src/app/utils/local-spinner';

@Component({
  selector: 'app-analysis-counts',
  templateUrl: './analysis-counts.component.html',
  styleUrls: ['./analysis-counts.component.scss']
})
export class AnalysisCountsComponent {

  @Input({ required: true }) set analysis(analysis: Analysis | null) {
    this._analysis = analysis;
    this.fetchAnalysisCounts();
  }

  @Output() public crossFilterValid = new EventEmitter<boolean>();

  public readonly JourneysOrPersons = JourneysOrPersons;

  public activeJourneyType = JourneysOrPersons.JOURNEYS;
  public isCrossFiltering = false;
  public crossFilteredJourneyCount = 0;
  public crossFilteredPersonCount = 0;
  public spinner = new LocalSpinner();

  private _analysis: Analysis | null = null;

  get analysis() {
    return this._analysis;
  }

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private toggleJourneysCountsService: ToggleJourneysCountsService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchAnalysisCounts();
      this.isCrossFiltering = crossFilteringService.isCrossFiltering();
    });
  }

  public changeActiveJourneyType(activeJourneyType: JourneysOrPersons) {
    this.activeJourneyType = activeJourneyType;
    this.toggleJourneysCountsService.setToggleJourneysOrPersons(activeJourneyType);
  }

  private fetchAnalysisCounts() {
    if (this.analysis) {
      const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
      this.analysisHttpService
        .getAnalysisCounts(this.analysis.analysisId, crossFilterOptions)
        .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
        .subscribe(result => {
          this.crossFilteredJourneyCount = result.journeys;
          this.crossFilteredPersonCount = result.persons;
          const isCrossFilterValid = this.crossFilteredPersonCount >= ValidationConstants.MIN_PEOPLE_FOR_VALID_ANALYSIS;
          this.crossFilterValid.emit(isCrossFilterValid);
        });
    } else {
      this.crossFilteredJourneyCount = 0;
      this.crossFilteredPersonCount = 0;
      this.crossFilterValid.emit(true);
    }
  }
}
