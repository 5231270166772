import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { DateTimeFilterEntry } from '@shared/resources/analysis/datetimefilter/date-time-filter-entry';
import { DateTimeFilterEntryUtils } from '@shared/utils/date-time-filter-entry-utils';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-date-time-filter-entry',
  templateUrl: './date-time-filter-entry.component.html',
  styleUrl: './date-time-filter-entry.component.scss'
})
export class DateTimeFilterEntryComponent {

  @Input({ required: true }) public dateTimeFilterEntry: DateTimeFilterEntry;
  @Input() public canDelete = false;

  @Output() public delete: EventEmitter<DateTimeFilterEntry> = new EventEmitter();

  public minimumDate = ValidationConstants.FIRST_JOURNEY_DATE;
  public maximumDate = new Date();

  constructor(authService: AuthService) {
    if (authService.isTrialUser) {
      this.minimumDate = DateTimeFilterEntryUtils.getMostRecentYearRange().startDate;
    }
  }

  public formatLabel(value: number): string {
    return `${value}`;
  }

  public onDeleteClick(dateTimeFilterEntryToDelete: DateTimeFilterEntry) {
    this.delete.emit(dateTimeFilterEntryToDelete);
  }

}
