<h2 mat-dialog-title>{{ 'DISCLAIMER.TITLE' | translate }}</h2>
<div mat-dialog-content class="mat-typography">
  <p>{{ 'DISCLAIMER.BODY_PARAGRAPH_1' | translate }}</p>
  <p>{{ 'DISCLAIMER.BODY_PARAGRAPH_2' | translate }}</p>
  <p>{{ 'DISCLAIMER.BODY_PARAGRAPH_3' | translate }}</p>

</div>
<mat-checkbox class="accept-disclaimer" [(ngModel)]="accepted" (click)="$event.stopPropagation();">
  {{ 'DISCLAIMER.ACCEPT_DISCLAIMER_TEXT' | translate }}
</mat-checkbox>
<div mat-dialog-actions class="buttons">
  <button mat-button [mat-dialog-close]="true" [color]="'primary'" cdkFocusInitial data-cy="primaryButton"
    [disabled]="!accepted" (click)="onCloseClick()">
    {{'BUTTON.OK' | translate}}
  </button>
</div>
