export class DateTimeFilterEntryUtils {

  private static readonly DATE_UPDATE_BUFFER_PERIOD_IN_DAYS = 14;

  public static getMostRecentYearRange(now = new Date()) {
    const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    endDate.setDate(endDate.getDate() - DateTimeFilterEntryUtils.DATE_UPDATE_BUFFER_PERIOD_IN_DAYS);
    endDate.setDate(0); // Last day of previous month
    const startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    startDate.setDate(startDate.getDate() - DateTimeFilterEntryUtils.DATE_UPDATE_BUFFER_PERIOD_IN_DAYS);
    startDate.setDate(1); // First day of current month
    return { startDate, endDate };
  }
}
