import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { DatesHistogramResponse } from '@shared/resources/analysis/dates-histogram-response';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphStyle } from 'src/app/utils/constants/graph-style';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';

@Component({
  selector: 'app-journeys-count-per-date-graph',
  templateUrl: './journeys-count-per-date-graph.component.html',
  styleUrl: './journeys-count-per-date-graph.component.scss'
})
export class JourneysCountPerDateGraphComponent implements OnInit {

  public readonly HEIGHT_PX = 225;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private panelType: PanelType = PanelType.TREND;
  private echartsInstance: echarts.ECharts;
  private datesHistogramResponse: DatesHistogramResponse['counts'] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {

    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchJourneysCountPerDate();
    });

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      if (this.echartsInstance) {
        this.echartsInstance.setOption(this.getChartOptions());
      }
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const translateInstant = this.activeToggle === JourneysOrPersons.JOURNEYS ? 'JOURNEYS' : 'PERSONS';
        const fileName = this.translateService.instant(`ANALYSIS_OVERVIEW.PANEL.TITLE.TREND.${translateInstant}`);
        this.chartService.exportDataToCsv(fileName, this.datesHistogramResponse);
      }
    });
  }

  public ngOnInit() {
    this.fetchJourneysCountPerDate();
  }

  private fetchJourneysCountPerDate() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getDatesHistogram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(journeysCount => {
        this.datesHistogramResponse = journeysCount.counts;
        this.initChartOnlyOnce();
        this.echartsInstance.setOption(this.getChartOptions());
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private getChartOptions() {
    return {
      xAxis: {
        type: 'time',
        ...GraphStyle.AXIS_STYLE
      },
      yAxis: {
        ...GraphStyle.AXIS_STYLE
      },
      ...GraphStyle.TEXT_STYLE,
      grid: {
        left: 40,
        right: 15,
        top: 5,
        bottom: 20
      },
      series: [
        {
          data: this.getChartData(),
          type: 'bar',
          color: Constants.COLOR_DAT_BLUE
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        ...GraphUtils.getTooltipFormatterForArrayData({
          journeysTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS'),
          personsTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS'),
          excludeColorCircle: true
        }),
        ...GraphStyle.TOOLTIP_STYLE
      }
    };
  }

  private getChartData() {
    return this.datesHistogramResponse.map(record => [record.date, this.activeToggle === JourneysOrPersons.JOURNEYS ? record.journeys : record.persons, record.journeys, record.persons]);
  }
}
