import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { CreateAnalysis } from '@shared/resources/analysis/create-analysis';
import { CrossFilterOptions } from '@shared/resources/analysis/cross-filter-options';
import { DatesHistogramResponse } from '@shared/resources/analysis/dates-histogram-response';
import { GetAnalysisCountsResponse } from '@shared/resources/analysis/get-analysis-counts-response';
import { HouseholdResponse } from '@shared/resources/analysis/household-response';
import { KpiOverviewResponse } from '@shared/resources/analysis/kpi-overview-response';
import { ModalSplitResponse } from '@shared/resources/analysis/modal-split-response';
import { ModeTransitionsResponse } from '@shared/resources/analysis/mode-chains-response';
import { ObjectivesResponse } from '@shared/resources/analysis/objectives-response';
import { OdFlowsResponse } from '@shared/resources/analysis/od-flows-response';
import { OriginOrDestination } from '@shared/resources/analysis/origin-or-destination';
import { TrendPerModeGroupResponse } from '@shared/resources/analysis/trend-per-mode-group-response';
import { UrbanisationResponse } from '@shared/resources/analysis/urbanisation-response';
import { PolygonLevel } from '@shared/resources/geography/polygon-level';
import { BaseHttpService } from './base-http.service';

export type BandwidthsFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.LineString, { journeys: number; persons: number; }>;
export type PolygonsFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon, { journeys: number; persons: number; }>;

@Injectable({
  providedIn: 'root'
})
export class AnalysisHttpService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public createAnalysis(createAnalysis: CreateAnalysis) {
    return this.postT<Analysis>(`analysis`, createAnalysis);
  }

  public updateAnalysis(analysisId: number, analysis: CreateAnalysis) {
    return this.putT<Analysis>(`analysis/${analysisId}`, analysis);
  }

  public getAnalysisList() {
    return this.get<Analysis[]>(`analysislist`);
  }

  public getAnalysisById(analysisId: number) {
    return this.get<Analysis>(`analysis/${analysisId}`);
  }

  public deleteAnalysis(analysisId: number) {
    return this.delete(`analysis/${analysisId}`);
  }

  public getAnalysisCounts(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<GetAnalysisCountsResponse>(`analysis/${analysisId}/counts`, crossFilterOptions);
  }

  public getBandwidthAnalysisGeoJson(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<BandwidthsFeatureCollection>(`analysis/${analysisId}/layers/bandwidths`, crossFilterOptions);
  }

  public getPolygonAnalysisGeoJson(analysisId: number, crossFilterOptions: CrossFilterOptions, type: OriginOrDestination, level: PolygonLevel) {
    return this.postT<PolygonsFeatureCollection>(`analysis/${analysisId}/layers/areas?type=${type}&level=${level}`, crossFilterOptions);
  }

  public getModalSplitDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<ModalSplitResponse>(`analysis/${analysisId}/diagrams/modalsplit`, crossFilterOptions);
  }

  public getDatesHistogram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<DatesHistogramResponse>(`analysis/${analysisId}/diagrams/dateshistogram`, crossFilterOptions);
  }

  public getModeGroupTrend(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<TrendPerModeGroupResponse>(`analysis/${analysisId}/diagrams/modegrouptrend`, crossFilterOptions);
  }

  public getOdFlowsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<OdFlowsResponse>(`analysis/${analysisId}/diagrams/odflows`, crossFilterOptions);
  }

  public getOdMatrix(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<OdFlowsResponse>(`analysis/${analysisId}/odmatrix`, crossFilterOptions);
  }

  public getHouseholdsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<HouseholdResponse>(`analysis/${analysisId}/diagrams/households`, crossFilterOptions);
  }

  public getUrbanisationDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<UrbanisationResponse>(`analysis/${analysisId}/diagrams/urbanisation`, crossFilterOptions);
  }

  public getObjectivesDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<ObjectivesResponse>(`analysis/${analysisId}/diagrams/objectives`, crossFilterOptions);
  }

  public getModeTransitionsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<ModeTransitionsResponse>(`analysis/${analysisId}/diagrams/modetransitions`, crossFilterOptions);
  }

  public getKpiValues(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.postT<KpiOverviewResponse>(`analysis/${analysisId}/diagrams/kpis`, crossFilterOptions);
  }
}
