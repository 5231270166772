import { Component, Input } from '@angular/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { assert } from '@shared/utils/assert';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { GeographyHttpService } from 'src/app/services/http/geography-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { DateTimeFilterOverviewEntry, DateTimeFilterOverviewGenerator } from './date-time-filter-overview-generator';
import { GeoFilterOverviewEntry, GeoFilterOverviewGenerator } from './geo-filter-overview-generator';
import { PersonFilterOverviewEntry, PersonFilterOverviewGenerator } from './person-filter-overview-generator';
import { TransportFilterOverviewEntry, TransportFilterOverviewGenerator } from './transport-filter-overview-generator';

@Component({
  selector: 'app-analysis-filter-overview',
  templateUrl: './analysis-filter-overview.component.html',
  styleUrl: './analysis-filter-overview.component.scss'
})
export class AnalysisFilterOverviewComponent {

  public personsFromAnalysis: Analysis | null;

  public geoFilterOverviewEntries: GeoFilterOverviewEntry[] = [];
  public dateTimeFilterOverviewEntries: DateTimeFilterOverviewEntry[] = [];
  public transportFilterOverviewEntries: TransportFilterOverviewEntry[] = [];
  public personFilterOverviewEntries: PersonFilterOverviewEntry[] = [];

  private geoFilterOverviewGenerator: GeoFilterOverviewGenerator;
  private dateTimeFilterOverviewGenerator: DateTimeFilterOverviewGenerator;
  private transportFilterOverviewGenerator: TransportFilterOverviewGenerator;
  private personFilterOverviewGenerator: PersonFilterOverviewGenerator;

  private _analysis: Analysis | null;

  constructor(
    private geographyHttpService: GeographyHttpService,
    private analysisHttpService: AnalysisHttpService,
    private spinnerService: SpinnerService,
  ) {
    this.geoFilterOverviewGenerator = new GeoFilterOverviewGenerator(this.geographyHttpService);
    this.dateTimeFilterOverviewGenerator = new DateTimeFilterOverviewGenerator();
    this.transportFilterOverviewGenerator = new TransportFilterOverviewGenerator();
    this.personFilterOverviewGenerator = new PersonFilterOverviewGenerator();
  }

  @Input({ required: true }) set analysis(analysis: Analysis | null) {
    if (analysis && analysis !== this._analysis) {
      this._analysis = analysis;
      this.initFilterOverview();
    }
  }

  get analysis() {
    return this._analysis;
  }

  private initFilterOverview() {
    assert(this.analysis);

    const geofilter = this.analysis.analysisFilterOptions.geoFilter;
    const dateTimeFilter = this.analysis.analysisFilterOptions.dateTimeFilter;
    const transportFilter = this.analysis.analysisFilterOptions.transportFilter;
    const personFilter = this.analysis.analysisFilterOptions.personFilter;

    this.geoFilterOverviewGenerator.generate(geofilter)
      .pipe(this.spinnerService.register())
      .subscribe(spatialFilterEntries => this.geoFilterOverviewEntries = spatialFilterEntries);

    this.dateTimeFilterOverviewEntries = this.dateTimeFilterOverviewGenerator.generate(dateTimeFilter);
    this.transportFilterOverviewEntries = this.transportFilterOverviewGenerator.generate(transportFilter);
    this.personFilterOverviewEntries = this.personFilterOverviewGenerator.generate(personFilter);

    if (personFilter.personsFromAnalysisId) {
      this.analysisHttpService.getAnalysisById(personFilter.personsFromAnalysisId)
        .pipe(this.spinnerService.register())
        .subscribe(analysis => this.personsFromAnalysis = analysis);
    }

  }
}
