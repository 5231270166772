<form class="basic-info-container" [formGroup]="metadataForm">
  <div class="top-row">
    <div class="input-container">
      <mat-form-field class="title">
        <mat-label>
          {{ 'ANALYSIS_METADATA_INPUT.TITLE' | translate }}
        </mat-label>
        <input matInput placeholder="{{ 'ANALYSIS_METADATA_INPUT.TITLE' | translate }}" formControlName="title">
      </mat-form-field>
      @if (metadataForm.get('title')?.hasError('required') || metadataForm.get('title')?.hasError('whitespace')) {
      <mat-error>
        {{ 'ANALYSIS_METADATA_INPUT.ERROR.REQUIRED_TITLE' | translate }}
      </mat-error>
      }
    </div>

    <div class="input-container">
      <mat-form-field class="project-code">
        <mat-label>{{ 'ANALYSIS_METADATA_INPUT.PROJECT_CODE' | translate }}</mat-label>
        <input data-cy="project-code-input" matInput
          placeholder="{{ 'ANALYSIS_METADATA_INPUT.PROJECT_CODE' | translate }}" formControlName="projectCode">
      </mat-form-field>
    </div>
  </div>

  <mat-form-field class="description">
    <mat-label>{{ 'ANALYSIS_METADATA_INPUT.DESCRIPTION' | translate }}</mat-label>
    <input matInput placeholder="{{ 'ANALYSIS_METADATA_INPUT.DESCRIPTION' | translate }}" formControlName="description">
    <mat-hint align="end">{{ metadataForm.get('description')?.value.length }} / 200</mat-hint>
  </mat-form-field>
  @if (metadataForm.get('description')?.hasError('maxlength')) {
  <mat-error>
    {{ 'ANALYSIS_METADATA_INPUT.ERROR.MAX_LENGTH_EXCEEDED' | translate }}
  </mat-error>
  }
</form>
