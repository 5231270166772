import { AfterViewInit, Component, OnDestroy, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { Analysis } from '@shared/resources/analysis/analysis';
import { tap } from 'rxjs';
import { AppMonitorService } from 'src/app/services/app-monitor.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { MessageService } from 'src/app/services/message.service';
import { CustomEventsConstants } from 'src/app/utils/constants/custom-events-constants';
import { DisclaimerDialogComponent } from '../disclaimer-dialog/disclaimer-dialog.component';

@Component({
  selector: 'app-nvp-analysis-list',
  templateUrl: './nvp-analysis-list.component.html',
  styleUrl: './nvp-analysis-list.component.scss'
})
export class NvpAnalysisListComponent implements OnInit, AfterViewInit, OnDestroy {

  private static readonly DEFAULT_COLUMNS = ['name', 'projectCode', 'state', 'created', 'journeys', 'persons', 'actions'];
  private static readonly POLLING_INTERVAL_MS = 10_000;

  public readonly MAX_NUMBER_OF_ANALYSES = ValidationConstants.MAX_NUM_ANALYSES;
  public readonly trackByAnalysisId: TrackByFunction<Analysis> = (index: number, item: Analysis) => item.analysisId;

  public analysisListLoaded: boolean = false;
  public dataSource: MatTableDataSource<Analysis> = new MatTableDataSource();
  public displayedColumns: string[] = NvpAnalysisListComponent.DEFAULT_COLUMNS;
  public numberOfAnalyses = 0;

  private pollingTimerId: number;

  @ViewChild(MatSort) private sort: MatSort;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private dialog: MatDialog,
    private router: Router,
    private messageService: MessageService,
    private appMonitorService: AppMonitorService,
  ) {
  }

  public ngOnInit() {
    this.refreshAnalysisList();
  }

  public ngOnDestroy() {
    clearTimeout(this.pollingTimerId);
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    DisclaimerDialogComponent.showDisclaimer(this.dialog, { showIfAccepted: false });
  }

  public triggerMaximumAnalysesError() {
    this.messageService.showErrorSnackBar(
      'ANALYSIS_LIST.SNACKBAR_NUMBER_OF_ANALYSES_ERROR',
      { MAX_NUMBER_OF_ANALYSES: this.MAX_NUMBER_OF_ANALYSES }
    );
  }

  public deleteAnalysis(analysis: Analysis) {
    this.messageService.showOkCancelDialog(
      {
        titleLangKey: 'ANALYSIS_LIST.DELETE',
        messageLangKey: 'ANALYSIS_LIST.DELETE_CONFIRM',
        langParams: { title: analysis.title }
      }, undefined,
      () => {
        this.analysisHttpService.deleteAnalysis(analysis.analysisId).pipe(
          tap(() => {
            this.refreshAnalysisList();
          })
        ).subscribe({
          next: () => {
            this.appMonitorService.recordEvent(CustomEventsConstants.DELETE_ANALYSIS);
            this.messageService.showToaster('ANALYSIS_LIST.DELETE_SUCCESS');
          },
          error: (err) => {
            this.appMonitorService.recordError(err);
            this.messageService.showErrorSnackBar('ANALYSIS_LIST.DELETE_ERROR');
          }
        });
      });
  }

  private refreshAnalysisList() {
    this.analysisHttpService.getAnalysisList().subscribe(
      analysisList => {
        this.dataSource.data = analysisList;
        this.numberOfAnalyses = analysisList.length;
        this.analysisListLoaded = true;
        if (analysisList.some(a => a.state === 'CREATING')) {
          this.pollingTimerId = window.setTimeout(() => this.refreshAnalysisList(), NvpAnalysisListComponent.POLLING_INTERVAL_MS);
        }
      });
  }

}
