import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ModeWithUniModal } from '@shared/resources/analysis/cross-filter-options';
import { ModalSplitResponse } from '@shared/resources/analysis/modal-split-response';
import { ALL_TRANSPORT_MODES, TransportModeType } from '@shared/resources/analysis/transport-mode-type';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import * as echarts from 'echarts';
import { cloneDeep, sortBy } from 'lodash';
import { takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartService } from '../../services/chart.service';
import { GraphStyle } from '../../utils/constants/graph-style';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';

type ModalSplitData = ModalSplitResponse['modes'][0];

interface ModalSplitECElementEvent extends echarts.ECElementEvent {
  data: {
    name: string,
    leafLevel: 'mode' | 'uni-multi',
    uniModal: boolean;
    mode: TransportModeType,
    label: { formatter: string; },
    value: number,
    selected: boolean,
    journeys: number;
    persons: number;
    children: ModalSplitData[];
  };
}

@Component({
  selector: 'app-modal-split-chart',
  templateUrl: './modal-split-chart.component.html',
  styleUrls: ['./modal-split-chart.component.scss']
})
export class ModalSplitChartComponent implements OnInit {

  public readonly HEIGHT_PX = 350;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private panelType: PanelType = PanelType.MOVEMENTS;
  private echartsInstance: echarts.ECharts;
  private modalSplitData: ModalSplitData[] = [];
  private selectedCrossFilterModes: ModeWithUniModal[] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.selectedCrossFilterModes = cloneDeep(this.crossFilteringService.getCrossFilterOptions().modes);
      this.fetchModalSplitData();
    });

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      if (this.echartsInstance) {
        this.echartsInstance.setOption(this.getChartOptions(), true);
      }
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.MOVEMENTS');
        this.chartService.exportDataToCsv(fileName, this.modalSplitData);
      }
    });
  }

  public ngOnInit() {
    this.selectedCrossFilterModes = cloneDeep(this.crossFilteringService.getCrossFilterOptions().modes);
    this.fetchModalSplitData();
  }

  private fetchModalSplitData() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getModalSplitDiagram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(response => {
        this.modalSplitData = sortBy(response.modes, ['mode', 'uniModal']);
        this.initChartOnlyOnce();
        this.echartsInstance.setOption(this.getChartOptions(), true);
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('click', (event) => {
        this.toggleCrossFiltering(<ModalSplitECElementEvent>event);
      });
    }
  }

  private getChartOptions() {
    return this.activeToggle === JourneysOrPersons.PERSONS ? this.getChartOptionsNoPersonMode() : this.getChartOptionsFull();
  }

  private getChartOptionsNoPersonMode() {
    return {
      ...GraphStyle.TEXT_STYLE,
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: 'middle',
          style: {
            text: this.getTranslation('ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.NOT_AVAILABLE_FOR_PERSONS'),
            fontSize: 14,
            fill: 'black',
          },
        },
      ],
    };
  }

  private getChartOptionsFull() {
    return {
      tooltip: {
        trigger: 'item',
        ...this.getTooltipFormatter(),
        ...GraphStyle.TOOLTIP_STYLE
      },
      ...GraphStyle.TEXT_STYLE,
      series: [
        {
          type: 'sunburst',
          center: ['50%', '50%'],
          data: this.getChartData(),
          emphasis: {
            focus: 'self',
            itemStyle: {
              opacity: 1
            }
          },
          itemStyle: {
            borderWidth: 2,
            opacity: 0.2
          },
          selectedMode: 'multiple',
          select: {
            itemStyle: {
              opacity: 1
            }
          },
          nodeClick: false,
          levels: [
            {},
            {
              r0: 30,
              r: 120
            },
            {
              r0: 120,
              r: 140,
              label: {
                position: 'outside'
              }
            }
          ]
        }
      ]
    };
  }

  private getChartData() {
    const allModes = this.initializeAllModesData();
    this.populateAllModes(allModes);
    return this.mapModesToChartData(allModes);
  }

  private getTooltipFormatter() {
    const journeysTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS');
    const personsTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS');
    const totalJourneys = this.modalSplitData.map(r => r.journeys).reduce((sum, current) => sum + current, 0);

    return {
      formatter: function (params: any) {
        switch (params.treePathInfo.length) {
          case 2: // Level 2 (mode) Tooltip
            return GraphUtils.getTooltipFormatterExpression({
              journeysTooltip: journeysTooltip,
              totalJourneys: totalJourneys
            }, params);
          case 3: // Level 3 (uni/multi) Tooltip
            return GraphUtils.getTooltipFormatterExpression({
              journeysTooltip: journeysTooltip,
              totalJourneys: totalJourneys,
              personsTooltip: personsTooltip
            }, params);
          default:
            return ''; // Middle of diagram
        }
      }
    };
  }

  private initializeAllModesData(): Record<TransportModeType, { uniModal: { persons: number; journeys: number; }; multiModal: { persons: number; journeys: number; }; }> {
    const allModes: Record<TransportModeType, { uniModal: { persons: number; journeys: number; }; multiModal: { persons: number; journeys: number; }; }> = {} as Record<TransportModeType, { uniModal: { persons: number; journeys: number; }; multiModal: { persons: number; journeys: number; }; }>;

    for (const mode of ALL_TRANSPORT_MODES) {
      allModes[mode] = { uniModal: { persons: 0, journeys: 0 }, multiModal: { persons: 0, journeys: 0 } };
    }

    return allModes;
  }

  private populateAllModes(allModes: { [key in TransportModeType]: { uniModal: { persons: number, journeys: number; }; multiModal: { persons: number, journeys: number; }; } }) {
    for (const record of this.modalSplitData) {
      allModes[record.mode][record.uniModal ? 'uniModal' : 'multiModal'].persons += record.persons;
      allModes[record.mode][record.uniModal ? 'uniModal' : 'multiModal'].journeys += record.journeys;
    }
  }

  private mapModesToChartData(allModes: { [key in TransportModeType]: { uniModal: { persons: number, journeys: number; }; multiModal: { persons: number, journeys: number; }; } }) {
    const uniModalText = this.getTranslation('ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.UNI_MODAL');
    const multiModalText = this.getTranslation('ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.MULTI_MODAL');
    const isNoModeSelected = this.selectedCrossFilterModes.length === 0;

    return Object.keys(allModes).map((modeKey: string) => {
      const mode = modeKey as TransportModeType;
      const modeTranslation = this.getTranslation(`ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.${modeKey}`);
      const counts = allModes[mode];
      const children = [];

      const uniModalIsSelected = isNoModeSelected || this.selectedCrossFilterModes.some((m) => (m.mode === modeKey && m.uniModal));
      const multiModalIsSelected = isNoModeSelected || this.selectedCrossFilterModes.some((m) => (m.mode === modeKey && !m.uniModal));

      if (counts.uniModal.journeys !== 0) { // journeys !== 0 if-and-only-if persons !== 0. No need to check both
        children.push(this.formatChild(mode, modeTranslation, true, uniModalText, counts.uniModal, uniModalIsSelected));
      }

      if (counts.multiModal.journeys !== 0) {
        children.push(this.formatChild(mode, modeTranslation, false, multiModalText, counts.multiModal, multiModalIsSelected));
      }

      return children.length > 0 ? {
        name: modeTranslation,
        journeys: counts.uniModal.journeys + counts.multiModal.journeys,
        children: children,
        mode: mode,
        selected: uniModalIsSelected && multiModalIsSelected,
        leafLevel: 'mode',
        itemStyle: {
          color: Constants.COLORS_PER_MODE[mode] ?? Constants.COLORS_PER_MODE.UNKNOWN
        }
      } : undefined;
    }).filter(Boolean);
  }

  private formatChild(mode: TransportModeType, modeTranslation: string, isUniModal: boolean, uniMultiText: string, counts: { persons: number, journeys: number; }, isSelected: boolean) {
    return {
      name: `${modeTranslation} - ${uniMultiText}`,
      leafLevel: 'uni-multi',
      uniModal: isUniModal,
      mode,
      label: { formatter: uniMultiText },
      value: this.activeToggle === JourneysOrPersons.JOURNEYS ? counts.journeys : counts.persons,
      journeys: counts.journeys,
      persons: counts.persons,
      selected: isSelected,
      itemStyle: {
        color: Constants.COLORS_PER_MODE[mode] ?? Constants.COLORS_PER_MODE.UNKNOWN
      }
    };
  }

  private toggleCrossFiltering(event: ModalSplitECElementEvent) {
    switch (event.data.leafLevel) {
      case 'mode':
        this.toggleModeFiltering(event);
        break;
      case 'uni-multi':
        this.toggleUniMultiModalFiltering(event);
        break;
      default:
        exhaustiveCheck(event.data.leafLevel);
    }
    this.crossFilteringService.setModesFilter(Array.from(this.selectedCrossFilterModes));
  }

  private toggleUniMultiModalFiltering(event: ModalSplitECElementEvent) {
    const currentIndex = this.getCrossFilterModeWithUniModalIndex(event.data);
    if (currentIndex === -1) {
      this.selectedCrossFilterModes.push({ mode: event.data.mode, uniModal: event.data.uniModal });
    } else {
      this.selectedCrossFilterModes.splice(currentIndex, 1);
    }
  }

  private getCrossFilterModeWithUniModalIndex(data: ModalSplitData) {
    return (this.selectedCrossFilterModes.findIndex((m) => m.mode === data.mode && m.uniModal === data.uniModal));
  }

  private toggleModeFiltering(event: ModalSplitECElementEvent) {
    if (this.selectedCrossFilterModes.length !== 0 && event.data.selected) {
      this.selectedCrossFilterModes = this.selectedCrossFilterModes.filter(activeFilter => activeFilter.mode !== event.data.mode);
    } else {
      this.filterIfNotYetFiltered(event.data.mode, true);
      this.filterIfNotYetFiltered(event.data.mode, false);
    }
  }

  private filterIfNotYetFiltered(mode: TransportModeType, uniModal: boolean) {
    if (!this.selectedCrossFilterModes.find(f => f.mode === mode && f.uniModal === uniModal)) {
      this.selectedCrossFilterModes.push({ mode, uniModal });
    }
  }

  private getTranslation(key: string) {
    return this.translateService.instant(key);
  }

}
