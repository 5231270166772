<div class="user-journeys" [class.loading]="spinner.state$ | async">

  <button
    [class.selected]="activeJourneyType === JourneysOrPersons.JOURNEYS"
    class="kpi-block"
    (click)="changeActiveJourneyType(JourneysOrPersons.JOURNEYS)"
  >
    <mat-icon>move_location</mat-icon>
    <div class="kpi-content">
      <div class="label">{{ 'ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS' | translate }}</div>
      <div class="values">
        <div data-cy="journey-count" class="large-value">{{ crossFilteredJourneyCount | number }}</div>
        @if (analysis && isCrossFiltering) {
        <div data-cy="noncrossfiltered-journey-count" class="small-value">
          / {{ analysis.journeys | number }}
        </div>
        }
      </div>
    </div>
  </button>

  <button
    [class.selected]="activeJourneyType === JourneysOrPersons.PERSONS"
    class="kpi-block"
    (click)="changeActiveJourneyType(JourneysOrPersons.PERSONS)"
  >
    <mat-icon>person_pin</mat-icon>
    <div class="kpi-content">
      <div class="label">{{ 'ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS' | translate }}</div>
      <div class="values">
        <div data-cy="person-count" class="large-value">{{ crossFilteredPersonCount | number }}</div>
        @if (analysis && isCrossFiltering) {
        <div data-cy="noncrossfiltered-person-count" class="small-value">
          / {{ analysis.persons | number }}
        </div>
        }
      </div>
    </div>
  </button>

</div>
