export class GraphStyle {
  public static readonly TITLE_STYLE = {
    left: 'center',
    textStyle: {
      fontSize: 14
    }
  };

  public static readonly TEXT_STYLE = { textStyle: { fontSize: 11 } };

  public static readonly AXIS_STYLE = { axisLabel: { fontSize: 11 } };

  public static readonly TOOLTIP_STYLE = {
    ...GraphStyle.TEXT_STYLE
  };

}
