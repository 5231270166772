import { Component, Input } from '@angular/core';
import { KpiType } from '@shared/resources/analysis/kpi-type';

interface KpiDescriptor {
  icon: string;
  localeKey: string;
  measurementUnit: string;
}

@Component({
  selector: 'app-kpi-block',
  templateUrl: './kpi-block.component.html',
  styleUrls: ['./kpi-block.component.scss']
})
export class KpiBlockComponent {
  public kpiDescriptor: KpiDescriptor;

  @Input({ required: true }) public largeValue: number;

  @Input() public numberFormat = '1.0-0';

  @Input({ required: true }) set kpiType(type: KpiType) {
    this.kpiDescriptor = this.kpiDescriptors[type];
  }

  private kpiDescriptors: Record<KpiType, KpiDescriptor> = {
    'TRAVEL_TIME': {
      icon: 'acute',
      localeKey: 'AVG_TRAVEL_TIME',
      measurementUnit: 'MEASUREMENT_UNITS.MINUTES'
    },
    'SPEED': {
      icon: 'speed',
      localeKey: 'AVG_SPEED',
      measurementUnit: 'MEASUREMENT_UNITS.SPEED_IN_KILOMETERS'
    },
    'DISTANCE': {
      icon: 'straighten',
      localeKey: 'AVG_DISTANCE',
      measurementUnit: 'MEASUREMENT_UNITS.KILOMETERS'
    },
    'MULTIMODAL': {
      icon: 'train',
      localeKey: 'MULTIMODAL',
      measurementUnit: 'MEASUREMENT_UNITS.PERCENT'
    }
  };

  public getKpiDescriptor(kpi: KpiType): KpiDescriptor {
    return this.kpiDescriptors[kpi];
  }
}
