import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CrossFilterOptions, ModeWithUniModal } from '@shared/resources/analysis/cross-filter-options';
import { ObjectiveType } from '@shared/resources/analysis/objective-type';
import { HouseholdType } from '@shared/resources/analysis/personfilter/household-type';
import { UrbanDensityType } from '@shared/resources/analysis/personfilter/urban-density-type';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';

@Component({
  selector: 'app-cross-filter-chips',
  templateUrl: './cross-filter-chips.component.html',
  styleUrl: './cross-filter-chips.component.scss'
})
export class CrossFilterChipsComponent {
  public crossFilterOptions: CrossFilterOptions;

  constructor(private crossFilteringService: CrossFilteringService) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    });
  }

  public isAnyCrossFiltersApplied() {
    return this.crossFilterOptions?.modes.length > 0
      || this.crossFilterOptions?.households.length > 0
      || this.crossFilterOptions?.urbanDensities.length > 0
      || this.crossFilterOptions?.objectives.length > 0;
  }

  public resetCrossFilter() {
    this.crossFilteringService.resetFilter();
  }

  public removeCrossFilter(filterOptionToBeRemoved: ModeWithUniModal | HouseholdType | UrbanDensityType | ObjectiveType, crossFilterType: 'MODE' | 'HOUSEHOLD_TYPE' | 'URBANISATION' | 'OBJECTIVE') {
    switch (crossFilterType) {
      case ('MODE'): {
        const appliedModeFilter = filterOptionToBeRemoved as ModeWithUniModal;
        const newModes = this.crossFilterOptions.modes.filter(m => !(m.mode === appliedModeFilter.mode && m.uniModal === appliedModeFilter.uniModal));
        this.crossFilteringService.setModesFilter(newModes);
        break;
      }
      case ('HOUSEHOLD_TYPE'): {
        const appliedHouseholdFilter = filterOptionToBeRemoved as HouseholdType;
        const newHouseholds = this.crossFilterOptions.households.filter(houseHold => houseHold !== appliedHouseholdFilter);
        this.crossFilteringService.setHouseholdsFilter(newHouseholds);
        break;
      }
      case ('URBANISATION'): {
        const appliedUrbanFilter = filterOptionToBeRemoved as UrbanDensityType;
        const newUrbanDensities = this.crossFilterOptions.urbanDensities.filter(urbanDensity => urbanDensity !== appliedUrbanFilter);
        this.crossFilteringService.setUrbanDensitiesFilter(newUrbanDensities);
        break;
      }
      case ('OBJECTIVE'): {
        const appliedObjectiveFilter = filterOptionToBeRemoved as ObjectiveType;
        const newObjectives = this.crossFilterOptions.objectives.filter(objective => objective !== appliedObjectiveFilter);
        this.crossFilteringService.setObjectivesFilter(newObjectives);
        break;
      }
      default:
        throw exhaustiveCheck(crossFilterType);
    }
  }

}
